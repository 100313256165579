import styles from './ProfileCompletionProgress.module.scss'
import { Loader, ProgressBar, Text } from '@percent/lemonade'
import { Task } from '@percent/cause-dashboard/app/home/profileCompletionProgress/tasks/task/Task'
import { useAuthState, useCausesPortalAnalytics, useQuery } from '@percent/cause-dashboard/common/hooks'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { BankDetailStatus } from '@percent/domain/bank-details'
import { useTranslation } from 'react-i18next'
import { PageViewSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

const tasksAnalyticsEventMap = {
  profile: 'Profile details task link clicked',
  bank: 'Bank details task link clicked'
} as const

export const ProfileCompletionProgress = () => {
  const { authState } = useAuthState()
  const { claimAccepted } = selectAuthState(authState)
  const { bankDetailsService } = useServices()
  const { t } = useTranslation()
  const { track } = useCausesPortalAnalytics()

  const hasAddedBrandDetails =
    Boolean(authState.organisation?.logo) &&
    Boolean(authState.organisation?.displayName) &&
    Boolean(authState.organisation?.description)

  const [{ data: bankDetailsData, isLoading: isBankDetailsLoading }] = useQuery(bankDetailsService.getBankDetails, {})

  const hasAddedBankDetails = bankDetailsData?.status === BankDetailStatus.APPROVED

  const tasksToComplete = [claimAccepted, hasAddedBrandDetails, hasAddedBankDetails]
  const progress = Math.floor((tasksToComplete.filter(Boolean).length / tasksToComplete.length) * 100)

  const handleTaskLinkClick = (task: keyof typeof tasksAnalyticsEventMap) => {
    track(tasksAnalyticsEventMap[task], {
      organisation: authState.organisation?.displayName,
      organisationId: authState.organisation?.id,
      source: PageViewSource.Home,
      clickedFrom: PageViewSource.Home
    })
  }

  if (isBankDetailsLoading) {
    return (
      <div className={styles.wrapper}>
        <Loader isFullScreen={false} />
      </div>
    )
  }

  return (
    <div>
      <p className={styles.completeProfileTitle}>Raise money</p>
      <Text size="small">Complete your profile and make your profile more attractive to donors</Text>
      <div className={styles.progressBarWrapper}>
        <ProgressBar
          progressPercentage={progress}
          shadeColor="gray70"
          backgroundColorComplete="goodstack"
          backgroundColorIncomplete="goodstack"
        />
      </div>
      <div className={styles.tasksWrapper}>
        <Task
          title={{
            text: t(
              claimAccepted
                ? 'typography.home.tasks.claimAccount.title.complete'
                : 'typography.home.tasks.claimAccount.title.incomplete'
            )
          }}
          description={claimAccepted ? '' : t('typography.home.tasks.claimAccount.description.incomplete')}
          isCompleted={claimAccepted}
          disabled={!claimAccepted}
        />
        <Task
          title={
            hasAddedBrandDetails
              ? { text: t('typography.home.tasks.addBrandDetails.title.complete') }
              : {
                  text: t('typography.home.tasks.addBrandDetails.title.incomplete'),
                  ...(claimAccepted && { link: RoutePath.PROFILE, onClick: () => handleTaskLinkClick('profile') })
                }
          }
          description={hasAddedBrandDetails ? '' : t('typography.home.tasks.addBrandDetails.description.incomplete')}
          isCompleted={hasAddedBrandDetails}
          disabled={!claimAccepted}
        />
        <Task
          title={
            hasAddedBankDetails
              ? { text: t('typography.home.tasks.addBankDetails.title.complete') }
              : {
                  text: t('typography.home.tasks.addBankDetails.title.incomplete'),
                  ...(claimAccepted && { link: RoutePath.PROFILE, onClick: () => handleTaskLinkClick('bank') })
                }
          }
          description={hasAddedBankDetails ? '' : t('typography.home.tasks.addBankDetails.description.incomplete')}
          isCompleted={hasAddedBankDetails}
          disabled={!claimAccepted}
        />
      </div>
    </div>
  )
}
