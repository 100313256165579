import { HelpLinks } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { useAuthState, useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks'
import { PageViewSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export const links: Array<{ text: LocaleKey; href: string }> = [
  {
    text: 'typography.donations.helpLinks.link1',
    href: 'https://help.goodstack.org/hc/en-us/articles/28243376061201-How-can-I-increase-donations-for-my-nonprofits'
  },
  {
    text: 'typography.donations.helpLinks.link2',
    href: 'https://help.goodstack.org/hc/en-us/articles/28242768550545-Are-there-any-fees-applied-to-my-donations'
  },
  {
    text: 'typography.donations.helpLinks.link3',
    href: 'https://help.goodstack.org/hc/en-us/sections/28242757873553-Donation-Payment-Process'
  }
]

export const DonationsPageHelpLinks = () => {
  const { t } = useTranslation()
  const { authState } = useAuthState()
  const { track } = useCausesPortalAnalytics()

  const handleLinkClick = (text: string) => {
    track(`"${text}" help link clicked`, {
      organisation: authState.organisation?.displayName,
      organisationId: authState.organisation?.id,
      clickedFrom: PageViewSource.Donations,
      source: PageViewSource.Donations
    })
  }

  return (
    <HelpLinks
      title={t('typography.donations.helpLinks.title')}
      links={links.map(({ text, href }) => ({ text: t(text), href, onClick: () => handleLinkClick(t(text)) }))}
    />
  )
}
