import { AuthStateType } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { ProductOffer } from '@percent/cause-dashboard/app/productOffers/data/data.types'

const COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW = ['USA', 'CAN', 'AUS', 'FRA', 'GBR']

const canUserBookCall = (authState: AuthStateType) => {
  const { claimAccepted } = selectAuthState(authState)
  const myCountry = authState.organisation?.countryCode || authState.claimOrganisation?.countryCode
  const acceptCallsFromMyCountry = COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW.includes(myCountry || '')

  const canBookCall = claimAccepted && acceptCallsFromMyCountry

  return canBookCall
}

const isOfferAllowedInCountry = (authState: AuthStateType, allowedCountries?: string[]) => {
  const { claimAccepted } = selectAuthState(authState)
  const myCountry = authState.organisation?.countryCode || authState.claimOrganisation?.countryCode

  if (!allowedCountries) {
    return true
  }

  return claimAccepted && allowedCountries.includes(myCountry || '')
}

const shouldDisplayOffer = (productOffer: ProductOffer, canBookCall: boolean, isOfferAllowedInCountry: boolean) => {
  const isCalendlyCTA =
    productOffer.primaryButton.linkType === 'calendly' || productOffer.secondaryButton?.linkType === 'calendly'

  if (productOffer.globalOverride !== undefined) {
    return productOffer.globalOverride
  }

  if (isCalendlyCTA) {
    return productOffer.canBookACallOverride || canBookCall
  }

  return isOfferAllowedInCountry
}

export { canUserBookCall, isOfferAllowedInCountry, shouldDisplayOffer }
