import { useAuthDispatch, useAuthState, useFeatureFlag, useMutation } from '@percent/cause-dashboard/common/hooks'
import { Trans, useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useEffect, useMemo, useState } from 'react'
import { ErrorView, Loader, TitleCard } from '@percent/cause-dashboard/common/components'
import { SET_BANK_DETAILS } from '@percent/cause-dashboard/context/auth'
import { BankDetailsProps } from './BankDetails.types'
import { ProofBankSuccessModal } from './modal/ProofBankSuccessModal'
import { ProofBankErrorModal } from './modal/ProofBankErrorModal'
import { BankDetailsReview } from './bankDetailsReview/BankDetailsReview'
import styles from './BankDetails.module.scss'
import {
  BankDataView,
  BankDetailsReadView,
  BankDetailStatus,
  BankDetailsValidationResult,
  BankDetailsView,
  useAddBankDetailsForm,
  WireTransferType
} from '@percent/domain/bank-details'
import { Alert, Button, Text } from '@percent/lemonade'
import styled from 'styled-components'
import { BankDetailsValidationPayload } from '@percent/cause-dashboard/services/bankDetails/bankDetails.types'
import axios from 'axios'

const StyledSubmitButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: end;
`

const SUBMITTED_STATUSES = [BankDetailStatus.PENDING, BankDetailStatus.APPROVED]

export function BankDetails({ verifiedCauseOwner, organisationId }: BankDetailsProps) {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [openProofBankSuccessModal, setOpenProofBankSuccessModal] = useState(false)
  const [openProofBankErrorModal, setOpenProofBankErrorModal] = useState(false)
  const [isWaitingReview, setIsWaitingReview] = useState<boolean>(false)
  const [isAfterReview, setIsAfterReview] = useState<boolean>(false)
  const { bankDetailsService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const { authState } = useAuthState()
  const [isValidated, setIsValidated] = useState(false)
  const [savedValues, setSavedValues] = useState<(string | undefined)[]>([])
  const [bankData, setBankData] = useState<BankDetailsValidationResult | undefined>(undefined)
  const [isFileValid, setIsFileValid] = useState(false)
  const { bankDetailsValidation, bankDetailsValidationWithDocument } = useFeatureFlag()

  const bankDataTranslations = useMemo(
    () => ({
      bankName: t('bankDetails.bankName'),
      branchName: t('bankDetails.branchName'),
      addressLine1: t('bankDetails.addressLine1'),
      addressLine2: t('bankDetails.addressLine2'),
      addressLine3: t('bankDetails.addressLine3'),
      city: t('bankDetails.city'),
      state: t('bankDetails.state'),
      postalCode: t('bankDetails.postalCode'),
      country: t('bankDetails.country'),
      title: t('bankDetails.validation.title'),
      description: t('bankDetails.validation.description'),
      foundBankData: t('bankDetails.validation.foundBankData'),
      noDataFound: t('bankDetails.validation.noDataFound'),
      invalidDataTitle: t('bankDetails.validation.invalidDataTitle'),
      invalidDataDescription: t('bankDetails.validation.invalidDataDescription'),
      unknownValidationError: t('bankDetails.validation.unknownError'),
      validDataMessage: t('bankDetails.validation.validDataMessage'),
      validDataTitle: t('bankDetails.validation.validDataTitle'),
      proofOfBankAccountLabel: t('typography.proofBankAccount')
    }),
    [t]
  )

  const [{ isLoading: isFetchBankDetailsLoading }, { apiFunc: fetchBankData }] = useMutation(
    bankDetailsService.fetchBankData,
    async response => {
      setBankData(response.data.data)
      setIsValidated(true)
    },
    async () => {
      setBankData({ success: false, validationErrors: [] })
      setIsValidated(true)
    }
  )

  const [{ isLoading: isValidationWithDocumentLoading }, { apiFunc: validateBankDetailsWithDocument }] = useMutation(
    bankDetailsService.validateBankDetailsWithDocument,
    async response => {
      setBankData(response.data.data)
      setIsValidated(true)
    },
    async (err: Error) => {
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        setBankData({
          success: false,
          validationErrors: [
            {
              field: 'uploadDocument',
              message: (err.response?.data as unknown as { error: { message: string } }).error.message
            }
          ]
        })
      } else {
        setBankData({ success: false, validationErrors: [] })
      }
      setIsValidated(true)
    }
  )

  const isValidationLoading = isFetchBankDetailsLoading || isValidationWithDocumentLoading

  const [{ data: bankDetailsData, isLoading, errorMessage }, { refresh }] = useQuery(
    bankDetailsService.getBankDetails,
    {}
  )
  const handleOpenProofBankErrorModal = () => setOpenProofBankErrorModal(true)
  const handleCloseProofBankErrorModal = () => setOpenProofBankErrorModal(false)
  const handleCloseProofBankSuccessModal = () => {
    refresh()
    setOpenProofBankSuccessModal(false)
  }
  const handleOpenProofBankSuccessModal = () => {
    setIsWaitingReview(true)
    setOpenProofBankSuccessModal(true)
    authDispatch({
      type: SET_BANK_DETAILS,
      payload: {
        orgBankDetails: {
          ...authState.orgBankDetails,
          status: BankDetailStatus.PENDING
        }
      }
    })
  }

  const [{ isLoading: isPostLoading }, { apiFunc }] = useMutation(
    bankDetailsService.postBankDetails,
    () => handleOpenProofBankSuccessModal(),
    () => handleOpenProofBankErrorModal()
  )

  useEffect(() => {
    if (bankDetailsData?.status === BankDetailStatus.PENDING) {
      setIsWaitingReview(true)
    }

    if (bankDetailsData?.status === BankDetailStatus.APPROVED) {
      setIsAfterReview(true)
    }
  }, [bankDetailsData?.status])

  const bankDetailsStatusFlag = !isWaitingReview && !isAfterReview
  const isSubmitted = bankDetailsData?.status ? SUBMITTED_STATUSES.includes(bankDetailsData.status) : false

  const translations = {
    accountHolderName: t('typography.accountHolderName'),
    accountHolderPlaceholder: t('typography.accountHolderPlaceholder'),
    accountHolderDescription: t('typography.accountHolderDescription'),
    helperBankAccountText: t('typography.helperBankAccountText'),
    helperBankAccountLinkText: t('typography.helperBankAccountLinkText'),
    uploadDocumentPlaceholder: t('typography.uploadDocumentPlaceholder'),
    proofBankAccount: t('typography.proofBankAccount'),
    uploadDocument: t('button.uploadDocument'),
    submitBankDocuments: t('button.submitBankDocuments'),
    invalidDataTitle: t('bankDetails.validation.invalidDataTitle'),
    invalidDataDescription: t('bankDetails.validation.invalidDataDescription'),
    unknownValidationError: t('bankDetails.validation.unknownError')
  }

  const adjustedBankDetailsData = {
    ...bankDetailsData,
    addBankDetailsFormLabels: bankDetailsData?.requiredBankAccountDetails
  }

  const { formik, fields } = useAddBankDetailsForm({
    bankDetailsData: adjustedBankDetailsData,
    t,
    apiFunc,
    selectedFile
  })

  const isFormValid = adjustedBankDetailsData?.addBankDetailsFormLabels
    ? selectedFile && formik.isValid && formik.dirty && !isPostLoading && isFileValid
    : selectedFile && !isPostLoading && isFileValid

  const { setFieldError, setFieldTouched, handleSubmit, values } = formik

  useEffect(() => {
    if (bankDetailsValidation && isValidated && Object.values(values).some(value => !savedValues.includes(value))) {
      setIsValidated(false)
    }
  }, [values, setIsValidated, bankDetailsValidation, isValidated, savedValues])

  const handleSubmitFormWithValidation = (event?: React.FormEvent<HTMLFormElement>) => {
    if (!bankDetailsValidation) {
      return handleSubmit(event)
    }

    const requiredAccountDetails = bankDetailsData?.requiredBankAccountDetails
    if (!isValidated && requiredAccountDetails && organisationId) {
      const { holderName, ...payload } = values

      let paymentDetails: BankDetailsValidationPayload['paymentDetails']

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      switch (requiredAccountDetails.type) {
        case WireTransferType.IBAN:
          paymentDetails = {
            type: WireTransferType.IBAN,
            iban: payload.iban!,
            bic: payload.bic!
          }
          break
        case WireTransferType.DOMESTIC_WITH_BIC:
          paymentDetails = {
            type: WireTransferType.DOMESTIC_WITH_BIC,
            accountNumber: payload.accountNumber!,
            bic: payload.bic!,
            bankCode: payload.bankCode,
            branchCode: payload.branchCode
          }
          break
        case WireTransferType.DOMESTIC:
          paymentDetails = {
            type: WireTransferType.DOMESTIC,
            accountNumber: payload.accountNumber!,
            bankCode: payload.bankCode!
          }
          break
        default:
          throw new Error('Invalid payment details type')
      }

      if (bankDetailsValidationWithDocument) {
        validateBankDetailsWithDocument({
          organisationId,
          file: selectedFile!,
          bankAccountDetails: {
            holderName: holderName!,
            paymentDetails
          }
        })
      } else {
        fetchBankData({
          organisationId,
          holderName: holderName!,
          paymentDetails
        })
      }
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    } else {
      handleSubmit(event)
    }

    setSavedValues(Object.values(values))
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const fileFieldText =
    ((selectedFile || (bankDetailsData?.status !== BankDetailStatus.REJECTED && bankDetailsData?.previousFilename)) &&
      selectedFile?.name) ||
    bankDetailsData?.previousFilename

  const isDataValidated = !isValidationLoading && isValidated

  const isBankDataValid =
    bankData?.success === true || (bankData?.success === false && bankData?.validationErrors.length === 0)

  const isValidWithValidation = (isFormValid && isDataValidated && isBankDataValid) || (isFormValid && !isValidated)

  const isDisabled = bankDetailsValidation ? !isValidWithValidation : !isFormValid

  const bankDetailsContainer = (
    <>
      <ProofBankSuccessModal
        openProofBankSuccessModal={openProofBankSuccessModal}
        handleCloseProofBankSuccessModal={handleCloseProofBankSuccessModal}
      />
      <ProofBankErrorModal
        openProofBankErrorModal={openProofBankErrorModal}
        handleCloseProofBankErrorModal={handleCloseProofBankErrorModal}
      />
      {isWaitingReview && (
        <BankDetailsReview
          reviewBadgeText={t('typography.waitingReviewBadge')}
          reviewText={t('typography.waitingReviewText')}
          reviewLinkText={t('typography.reachOutToUsLink')}
          variant="info"
        />
      )}
      {isAfterReview && (
        <BankDetailsReview
          reviewBadgeText={t('typography.bankDetailsStatusText', {
            status: 'Verified'
          })}
          reviewText={t('typography.afterReviewText')}
          reviewLinkText={t('typography.reachOutToUsLink')}
          variant="success"
        />
      )}

      {bankDetailsStatusFlag && (
        <>
          <Text size="small" fontWeight="regular" className={styles.bankDetailsFormHeader}>
            {t('typography.donationText')}
          </Text>
          <div className={styles.bankDetailsAlertWrapper}>
            <Alert
              variant="info"
              title={
                <Trans
                  i18nKey="typography.whyWeNeedBankDetails"
                  components={{
                    Link: (
                      <a
                        className={styles.bankDetailsLink}
                        href="https://help.goodstack.org/hc/en-us/articles/31607683319057-Why-do-I-need-to-submit-my-organization-s-bank-details"
                        target="_blank"
                        rel="noreferrer"
                      />
                    )
                  }}
                />
              }
            />
          </div>
        </>
      )}
      {!isWaitingReview && bankDetailsData?.status === 'rejected' && (
        <BankDetailsReview
          variant="error"
          reviewBadgeText={t('typography.bankDetailsStatusText', {
            status: 'Rejected'
          })}
        />
      )}

      {(!bankDetailsData?.bankAccountDetails || !isSubmitted) && (
        <>
          <BankDetailsView
            setSelectedFile={setSelectedFile}
            buttonFlag={!bankDetailsValidation}
            uploadButtonFlag={bankDetailsStatusFlag}
            disableUploadBtn={!verifiedCauseOwner}
            disabledField={isSubmitted}
            bankDetailsData={adjustedBankDetailsData}
            selectedFile={selectedFile}
            isPostLoading={isPostLoading}
            fileFieldText={fileFieldText}
            translations={translations}
            formik={formik}
            fields={fields}
            bankData={bankData}
            isValidated={isValidated}
            onFileChange={() => setIsValidated(false)}
            onValidationFailed={() => setIsFileValid(false)}
            onValidationSuccess={() => setIsFileValid(true)}
          />
          {bankDetailsValidation && (
            <StyledSubmitButtonContainer>
              <Button
                type="submit"
                variant={isFormValid ? 'primary' : 'secondary'}
                disabled={isDisabled}
                data-testid="add-bank-details-submit-button"
                loading={isPostLoading || isValidationLoading}
                onPress={() => handleSubmitFormWithValidation()}
              >
                {!isValidated && bankDetailsValidation ? t('button.validateBankData') : t('button.submitBankDocuments')}
              </Button>
            </StyledSubmitButtonContainer>
          )}
        </>
      )}
      {isSubmitted && bankDetailsData?.bankAccountDetails && (
        <BankDetailsReadView
          bankAccountDetails={bankDetailsData.bankAccountDetails}
          translations={translations}
          fileFieldText={fileFieldText}
        />
      )}
    </>
  )

  const bankValidationContainer = (
    <BankDataView
      setFieldError={setFieldError}
      setFieldTouched={setFieldTouched}
      bankData={bankData}
      isValidated={isValidated}
      fields={fields}
      translations={bankDataTranslations}
      variant="body1"
    />
  )

  return (
    <>
      <TitleCard title={t('typography.bankDetails')} size={7}>
        {bankDetailsContainer}
      </TitleCard>
      {bankDetailsValidation && (!bankDetailsData?.bankAccountDetails || !isSubmitted) && (
        <TitleCard title={t('bankDetails.validation.title')} size={5}>
          {bankValidationContainer}
        </TitleCard>
      )}
    </>
  )
}
