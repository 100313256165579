import styled from 'styled-components'
import { Icon } from '../icon'

type ExternalLinkProps = {
  text: string
  href: string
  onClick?: () => void
  className?: string
}

const StyledExternalLink = styled.a`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${theme.colors.goodstack};
  font-size: ${theme.fontSizes.bodySmall};
  font-weight: ${theme.fontWeights.medium};
  text-decoration: underline;

  > svg {
    transform: rotate(45deg);
  }
`}
`

export const ExternalLink = ({
  text,
  href,
  onClick,
  className,
}: ExternalLinkProps) => (
  <StyledExternalLink
    href={href}
    target="_blank"
    rel="noreferrer"
    onClick={onClick}
    className={className}
  >
    <Icon name="arrow-up" size="m" />
    <span>{text}</span>
  </StyledExternalLink>
)
