import { Grid, Typography } from '@material-ui/core'
import { useAuthState, useMutation, usePermissionCheck, useQuery } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import styles from './ProfilePage.module.scss'
import { Loader, TitleCard, ClaimStatus } from '@percent/cause-dashboard/common/components'
import { CLAIM_STATUS } from '@percent/cause-dashboard/constants/claimStatus'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { ProfileForm } from './profileForm/ProfileForm'
import { BankDetails } from './bankDetails/BankDetails'
import { BookCallCard } from '@percent/cause-dashboard/app/causeProfile/bookCallCard/BookCallCard'
import { useState } from 'react'
import { useCalendlyEventListener } from 'react-calendly'

export function ProfilePage() {
  const { t } = useTranslation()

  const { causeService, calendlyService } = useServices()
  const [{ isLoading: isSavingCalendlyMeeting }, { apiFunc: saveMeetingLink }] = useMutation(
    calendlyService.saveMeetingLink
  )
  const [selectedButtonName, setSelectedButtonName] = useState<string | undefined>(undefined)

  const {
    authState: { user, organisation, claimOrganisation }
  } = useAuthState()
  const verifiedCauseOwner = !!user?.organisationId
  const organisationId = organisation?.id || claimOrganisation?.id

  const [{ data: cause, isLoading }, { refresh }] = useQuery(causeService.getOrganisation, {
    organisationId: organisationId || ''
  })
  const causeName = cause?.name
  const causeDisplayName = cause?.displayName
  const causeDescription = cause?.description
  const causeRegistryId = cause?.registryId
  const causeAddress = cause?.address
  const causeLogo = cause?.logo
  const handleClaimStatus = () => {
    if (claimOrganisation?.rejectedAt) {
      return CLAIM_STATUS.rejected
    }

    if (verifiedCauseOwner) {
      return CLAIM_STATUS.verified
    }

    return CLAIM_STATUS.pending
  }
  const claimStatus = handleClaimStatus()

  const { userCan } = usePermissionCheck()
  const canReadWriteBankDetails = userCan(['bank_details:write', 'bank_details:read'])
  const buttonName = 'Book-demo-button'

  useCalendlyEventListener({
    onEventScheduled: event => {
      const uri = event.data.payload.event.uri
      if (uri && !isSavingCalendlyMeeting) {
        saveMeetingLink({
          accountId: user?.id,
          organisationId: user?.organisationId ?? '',
          email: user?.email,
          source: buttonName,
          meetingLink: uri
        })
      }
      setSelectedButtonName(undefined)
    }
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={verifiedCauseOwner ? styles.verifiedContainer : styles.unVerifiedContainer}>
      <BookCallCard
        selectButton={setSelectedButtonName}
        selectedButtonName={selectedButtonName}
        buttonName={buttonName}
      />
      <Grid container spacing={4}>
        <TitleCard title={t('card.causeDetails')} description={t('card.profileDescription')} size={7}>
          <ProfileForm
            verifiedCauseOwner={verifiedCauseOwner}
            causeId={organisationId}
            causeName={causeName}
            causeDisplayName={causeDisplayName}
            causeDescription={causeDescription}
            refreshApi={refresh}
            causeLogo={causeLogo}
          />
        </TitleCard>
        <TitleCard title={t('card.charityDetails')} size={5}>
          <Typography variant="subtitle1" color="secondary">
            {causeName}
          </Typography>
          <Typography color="secondary" variant="body1" className={styles.address}>
            {causeAddress}
          </Typography>
          <Typography variant="body1" className={styles.charityNumber}>
            {t('typography.registeredId')}: {causeRegistryId}
          </Typography>
          <div className={styles.status}>
            <Typography variant="body1">{t('typography.accountStatus')}:</Typography>
            <ClaimStatus claimStatus={claimStatus} />
          </div>
        </TitleCard>
        {canReadWriteBankDetails && verifiedCauseOwner && (
          <BankDetails verifiedCauseOwner={verifiedCauseOwner} organisationId={organisation?.id} />
        )}
      </Grid>
    </div>
  )
}
