import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { ProgramName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export const percentServicesCatalog = {
  googleAdsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Ad Grant ongoing management',
    tracking: ProgramName.GoogleAdGrant,
    description: 'Create, manage, and optimize your Google Ad Grant with help from digital marketing specialists',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cnvs-9pd-252/meet-your-goodstack-advisor',
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  googleAdGrantSetUpPackage: {
    type: ProductOfferType.PercentService,
    name: 'Google Ad Grant Set Up Package',
    tracking: ProgramName.GoogleAdGrantSetUpPackage,
    description:
      'Have a Google Ad Grant Agency apply, set up, and build a campaign in compliance with the Ad Grant policies. Languages supported: English, Spanish & French',
    primaryButton: {
      linkType: 'external',
      link: 'https://buy.stripe.com/cN24h00gkeat24g4gA',
      linkText: 'productOffers.buyNow450'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/crqx-3dp-b8x/meet-your-goodstack-advisor',
      linkText: 'productOffers.bookCall'
    }
  },
  managedItSupport: {
    type: ProductOfferType.PercentService,
    name: 'Managed IT support',
    tracking: ProgramName.ManagedIT,
    description: 'Access one-off or ongoing IT support for software planning and implementation at $150/hour.',
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/crxy-wj3-zy3/meet-your-goodstack-advisor',
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  socialMediaManagement: {
    type: ProductOfferType.PercentService,
    name: 'Social media management',
    tracking: ProgramName.SocialMedia,
    description:
      'Get expert social media strategic training or ongoing management to boost engagement and raise awareness starting at $400.',
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cntj-sb7-25y/meet-your-goodstack-advisor',
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  websiteCmsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Website & CMS support',
    tracking: ProgramName.WebCMS,
    description: 'Engage your audience with expertly designed and managed websites tailored to your mission’s needs.',
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cm86-q6k-k53/meet-your-goodstack-advisor',
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  dataMigrations: {
    type: ProductOfferType.PercentService,
    name: 'Data migrations',
    tracking: ProgramName.DataMigrations,
    description: 'Let experts handle your email and data migration seamlessly. Schedule a call for a quote.',
    primaryButton: {
      linkType: 'calendly',
      link: 'https://calendly.com/d/cq7z-qr3-csw/meet-your-goodstack-advisor',
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  }
} as const

type PercentServicesKeys = keyof typeof percentServicesCatalog

export const percentServices = percentServicesCatalog as Record<PercentServicesKeys, ProductOffer>
