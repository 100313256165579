import { HelpLinks } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { useAuthState, useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks'
import { PageViewSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export const links: Array<{ text: LocaleKey; href: string }> = [
  {
    text: 'typography.home.helpLinks.link1',
    href: 'https://help.goodstack.org/hc/en-us/sections/28239963680657-I-have-a-general-query-about-my-existing-application/'
  },
  {
    text: 'typography.home.helpLinks.link2',
    href: 'https://help.goodstack.org/hc/en-us/sections/28237809498129-Status-of-application/'
  },
  {
    text: 'typography.home.helpLinks.link3',
    href: 'https://help.goodstack.org/hc/en-us/sections/28239978912785-I-have-been-denied-a-discount/'
  }
]

export const HomePageHelpLinks = () => {
  const { t } = useTranslation()
  const { authState } = useAuthState()
  const { track } = useCausesPortalAnalytics()

  const handleLinkClick = (text: string) => {
    track(`"${text}" help link clicked`, {
      organisation: authState.organisation?.displayName,
      organisationId: authState.organisation?.id,
      clickedFrom: PageViewSource.Home,
      source: PageViewSource.Home
    })
  }

  return (
    <HelpLinks
      title={t('typography.home.helpLinks.title')}
      description={t('typography.home.helpLinks.description')}
      links={links.map(({ text, href }) => ({ text: t(text), href, onClick: () => handleLinkClick(t(text)) }))}
    />
  )
}
