import { Heading, Spacer, Text } from '@percent/lemonade'
import { MultiStepFormHeaderProps } from './multi-step-form-header.types'

export function MultiStepFormHeader({
  title,
  subtitle,
}: MultiStepFormHeaderProps) {
  return (
    <>
      {title && (
        <>
          <Spacer size={8} axis="vertical" />
          <Heading level="h1" align="left" data-testid="form-title">
            {title}
          </Heading>
        </>
      )}
      {subtitle && (
        <>
          <Spacer size={6} axis="vertical" />
          <Text align="left" dataTestId="form-subtitle">
            {subtitle}
          </Text>
        </>
      )}
    </>
  )
}
