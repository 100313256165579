import { getDataFromPBPResponse } from '@percent/cause-dashboard/common/utility/pbpApi'
import { PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import {
  BankDetailsServiceCapabilities,
  BankDetailsValidationPayload,
  BankDetailsValidationWithDocumentPayload,
  PostBankDetailsProps
} from './bankDetails.types'
import { BankDetail } from '@percent/domain/bank-details'

export const bankDetailsService = ({ percentClient }: BankDetailsServiceCapabilities) => ({
  postBankDetails: ({ payload }: PostBankDetailsProps) =>
    percentClient.post<PBPResponse<BankDetail>>('v1/bank-details-review', payload.formData),
  getBankDetails: () =>
    percentClient.get<PBPResponse<BankDetail>>('v1/bank-details-review').then(getDataFromPBPResponse),
  fetchBankData: ({ organisationId, ...payload }: BankDetailsValidationPayload) =>
    percentClient.post(`v1/bank-details-reviews/${organisationId}/fetch-bank-data`, payload),
  validateBankDetailsWithDocument: async ({
    organisationId,
    file,
    ...payload
  }: BankDetailsValidationWithDocumentPayload) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('bankAccountDetails', JSON.stringify(payload.bankAccountDetails))

    return percentClient.post(`v1/bank-details-reviews/${organisationId}/validate-bank-details`, formData)
  }
})
