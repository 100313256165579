import styles from 'apps/cause-dashboard/src/app/home/profileCompletionProgress/tasks/task/Task.module.scss'
import { FakeCheckbox } from 'libs/shared/ui-lemonade/src/components/fake-checkbox'
import { Text } from '@percent/lemonade'
import { Link } from 'react-router-dom'

type TaskProps = {
  title: {
    text: string
    link?: string
    onClick?: VoidFunction
  }
  description: string
  isCompleted: boolean
  disabled?: boolean
}

export const Task = ({ title, description, isCompleted, disabled }: TaskProps) => (
  <div className={disabled ? styles.wrapperDisabled : styles.wrapper}>
    <div className={disabled ? styles.titleWrapperDisabled : styles.titleWrapper}>
      <FakeCheckbox isSelected={isCompleted} />
      {title.link ? (
        <Link to={title.link} className={styles.link} onClick={title.onClick}>
          <Text size="small">{title.text}</Text>
        </Link>
      ) : (
        <Text size="small">{title.text}</Text>
      )}
    </div>
    <p className={styles.description}>{description}</p>
  </div>
)
