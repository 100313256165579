export enum MarketplaceProgrammeVisitedSource {
  Banner = 'Banner',
  Card = 'Card',
  Popup = 'Popup'
}

export enum ProgrammeViewEventKind {
  BannerShown = 'Banner shown',
  ModalShown = 'Calendly modal shown',
  PopupShown = 'Pop up shown',
  PopupDismissed = 'Pop up dismissed'
}

export enum PageViewSource {
  AppStore = 'App store',
  Profile = 'Profile page',
  Home = 'Home page',
  Donations = 'Donations page'
}

export enum ProgramGenericEventKind {
  ButtonClicked = 'Button clicked',
  Viewed = 'Viewed'
}

export const metadataKeys = ['originCategory', 'offerName', 'offerType', 'hasBuyNowOption'] as const

export type MarketingMetadataKey = typeof metadataKeys[number]

export type MarketingMetadataKeyStore = MarketingMetadataKey | 'buttonText'

export enum ProgramName {
  // Partner
  Adobe = 'ADOBE',
  Airslate = 'AIRSLATE',
  Asana = 'ASANA',
  Atlassian = 'ATLASSIAN',
  Auth0 = 'AUTH0',
  Box = 'BOX',
  BryteBridge = 'BRYTE_BRIDGE',
  Canva = 'CANVA',
  ConstantContact = 'CONSTANT_CONTACT',
  Docusign = 'DOCUSIGN',
  Eventbrite = 'EVENTBRITE',
  Fiverr = 'FIVERR',
  Givebutter = 'GIVEBUTTER',
  Google = 'GOOGLE',
  GrantSourcing = 'GRANT_SOURCING',
  Gusto = 'GUSTO',
  Hootsuite = 'HOOTSUITE',
  HootSuiteWebinar = 'HOOTSUITE_WEBINAR',
  HootsuiteSocialMedia = 'HOOTSUITE_SOCIAL_MEDIA',
  LinkedInFundraise = 'LINKEDIN_FUNDRAISE',
  LinkedInHire = 'LINKEDIN_HIRE',
  MondayCom = 'MONDAY_COM',
  NewRelic = 'NEW_RELIC',
  NordLayer = 'NORDLAYER',
  Okta = 'OKTA',
  OpenAI = 'OPENAI',
  PandaDoc = 'PANDADOC',
  Sage = 'SAGE',
  Splunk = 'SPLUNK',
  Twilio = 'TWILIO',
  QuickBooks = 'QUICKBOOKS',

  // Service
  GoogleAdGrant = 'GOOGLE_AD_GRANT',
  GoogleAdGrantSetUpPackage = 'GOOGLE_AD_GRANT_SET_UP_PACKAGE',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  ManagedIT = 'MANAGED_IT',
  ProfessionalGrantWriters = 'PROFESSIONAL_GRANT_WRITERS',
  SocialMedia = 'SOCIAL_MEDIA',
  AccountingFinancial = 'ACCOUNTING_FINANCIAL_SERVICES',
  WebCMS = 'WEB_CMS_SUPPORT',
  Fundraising = 'FUNDRAISING_SUPPORT',
  Ping = 'PING',
  DataMigrations = 'DATA_MIGRATIONS'
}

export enum CategoryName {
  TopTools = 'TOP_TOOLS',
  ExpertServices = 'EXPERT_SERVICES',
  DiscountedSoftware = 'DISCOUNTED_SOFTWARE'
}

export type ProgramTracking = {
  name: ProgramName
  category: CategoryName
}
