import styled from 'styled-components'
import { Card, ExternalLink, Typography } from '@percent/lemonade'

type HelpLinksProps = {
  title: string
  description?: string
  links: Array<{ text: string; href: string; onClick?: () => void }>
  className?: string
}

const Wrapper = styled(Card)`
  ${({ theme }) => `
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes.xxs};
    box-shadow: none;
    padding: ${theme.sizes.m};
    width: 100%;
  `}
`

const Title = styled.h2`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.h2};
    font-weight: ${theme.fontWeights.bold};
    margin: 0;
  `}
`

const Description = styled(Typography)`
  margin: 0;
`

const LinksWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.sizes.l};
    margin-top: ${theme.sizes.s}
  `}
`

export const HelpLinks = ({
  title,
  description,
  links,
  className,
}: HelpLinksProps) => (
  <Wrapper className={className}>
    <Title>{title}</Title>
    {description && <Description variant="default">{description}</Description>}
    <LinksWrapper>
      {links.map(({ text, href, onClick }, index) => (
        <ExternalLink key={index} text={text} href={href} onClick={onClick} />
      ))}
    </LinksWrapper>
  </Wrapper>
)
