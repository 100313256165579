import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'

import styles from './ProductOffersPage.module.scss'

import {
  categorizedProducts as categorizedProductsDefault,
  IProductCategory
} from '@percent/cause-dashboard/app/productOffers/data/categories'
import {
  useAuthState,
  useCausesPortalAnalytics,
  useMutation,
  useNotificationBar,
  useSitewideMessage
} from '@percent/cause-dashboard/common/hooks'
import { MarketplaceProgrammeVisitedSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useCalendlyEventListener } from 'react-calendly'
import { AppStoreCard } from './AppStoreCard/AppStoreCard'
import { MarketplaceBanner } from './MarketPlaceBanner/MarketplaceBanner'
import {
  canUserBookCall,
  isOfferAllowedInCountry,
  shouldDisplayOffer
} from '@percent/cause-dashboard/common/utility/productOffers/productOffers'

interface ProductOffersPageProps {
  offersOverride?: Array<{ id: number } & IProductCategory>
}

export function ProductOffersPage({ offersOverride }: ProductOffersPageProps) {
  const { page } = useCausesPortalAnalytics()
  const { calendlyService } = useServices()
  const { authState } = useAuthState()
  const [{ isLoading }, { apiFunc: saveMeetingLink }] = useMutation(calendlyService.saveMeetingLink)
  const [selectedOfferName, setSelectedOfferName] = useState<string | undefined>(undefined)

  const categorizedProducts = offersOverride ?? categorizedProductsDefault

  const canBookCallResult = canUserBookCall(authState)

  useEffect(() => {
    page('Marketplace')
  }, [])

  // Has to be put in parent not in PartnerCard
  // Listener is global, and was triggering for each card causing multiple http calls
  useCalendlyEventListener({
    onEventScheduled: event => {
      const uri = event.data.payload.event.uri
      if (uri && !isLoading) {
        saveMeetingLink({
          accountId: authState.user?.id,
          organisationId: authState.user?.organisationId ?? '',
          email: authState.user?.email,
          source: selectedOfferName ?? 'app-store-offer',
          meetingLink: uri
        })
      }
      setSelectedOfferName(undefined)
    }
  })

  const notification = useNotificationBar()
  const { shouldShowSitewideMessage } = useSitewideMessage()
  const defaultTop = 70
  const shiftDownToAccomodateNotification = notification.isOpened || shouldShowSitewideMessage ? 48 : 0
  const topPosition = defaultTop + shiftDownToAccomodateNotification

  return (
    <div id="marketplace-container">
      <MarketplaceBanner
        tracking={{
          source: MarketplaceProgrammeVisitedSource.Banner,
          programmeName: 'LinkedIn'
        }}
      />
      <div className={styles.productCategoriesWrapper}>
        {categorizedProducts
          .filter(category =>
            category.offers.some(
              offer =>
                shouldDisplayOffer(
                  offer,
                  canBookCallResult,
                  isOfferAllowedInCountry(authState, offer.allowedCountries)
                ) && category.offers.length > 0
            )
          )
          .map(category => (
            <section
              key={category.id}
              title={category.name}
              className={styles.productOffersWrapper}
              data-testid={category.name}
            >
              <div className={styles.sectionHeader} style={{ top: topPosition }}>
                <div className={styles.sectionTitle}>{category.name}</div>
                <div className={styles.sectionDescription}>{category.description}</div>
              </div>
              <Grid container spacing={3} className={styles.productOffersPageGrid}>
                {category.offers.map(productOffer => {
                  return (
                    shouldDisplayOffer(
                      productOffer,
                      canBookCallResult,
                      isOfferAllowedInCountry(authState, productOffer.allowedCountries)
                    ) && (
                      <Grid item sm={12} md={6} lg={4} xl={3} key={productOffer.name}>
                        <AppStoreCard
                          {...productOffer}
                          category={category.name}
                          selectOffer={setSelectedOfferName}
                          selectedOfferName={selectedOfferName}
                          tracking={{
                            name: productOffer.tracking,
                            category: category.tracking
                          }}
                        />
                      </Grid>
                    )
                  )
                })}
              </Grid>
            </section>
          ))}
      </div>
    </div>
  )
}
